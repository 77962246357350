<template>
  <b-form-group v-if="!is_second">
    <validation-provider
      #default="{ errors }"
      name="Educational year"
      rules="required"
    >
      <b-form-select
        v-model="form_data.eduyear_id"
        :options="EDUYears"
        :state="errors.length > 0 ? false : null"
        text-field="name"
        value-field="id"
        id="Educational-year"
        name="Educational year"
        :placeholder="$t('g.educational_year')"
        class="edu-years"
      />
      <small class="text-danger">
        {{ errors[0] && $t(`g.alert.${errors[0]}`) }}
      </small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormSelect } from "bootstrap-vue";
import { ValidationProvider } from "vee-validate";
import { required } from "@validations";
export default {
  components: {
    BFormGroup,
    ValidationProvider,
    BFormSelect,
  },
  props: {
    is_second: {
      type: Boolean,
      default: false,
    },
    form_data: Object,
  },
  data() {
    return {
      EDUYears: [
        {
          name: this.$t("g.educational_year"),
          value: null,
          disabled: true,
          seleceted: true,
        },
      ],
      required,
    };
  },
  created() {
    this.getEDUYears();
  },
  methods: {
    getEDUYears() {
      this.$http
        .get(`student/edu-years`)
        .then((res) => {
          this.EDUYears.push(...res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./SignupEduYearComponent.scss";
</style>
