<template>
  <div class="sigup-progress">
    <div class="w-100 image-signup">
      <b-img fluid :src="logoImg" alt="Login" class="d-block m-auto" />
    </div>
    <b-card-text class="mb-2 signup">
      <h1 class="text-center upper h1">{{ $t("g.signup") }}</h1>
      <p class="text-center">{{ $t("g.please_enter_your_details") }}</p>
    </b-card-text>

    <div class="box-circle mt-5 mb-3">
      <div class="circles d-flex justify-content-between align-items-center">
        <div class="circle d-flex justify-content-center align-items-center">
          <img :src="circleImg" alt="" />
        </div>
        <hr :style="{ 'border-color': borderColor }" />
        <div
          class="circle d-flex justify-content-center align-items-center"
          :style="{ 'border-color': borderColor }"
        >
          <img :src="is_second ? circleImg2 : null" alt="" />
        </div>
      </div>
      <div
        class="text-circle d-flex justify-content-between align-items-center mx-auto"
      >
        <span>{{ $t("g.edu_year") }}</span>
        <span :style="{ color: borderColor }">{{ $t("g.personal_info") }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCardText,
  BImg
} from "bootstrap-vue";
export default {
  components:{
    BCardText,
    BImg
  },
  props: {
    is_second: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      logoImg: require(`@/assets/images/futuer-icons/logo.svg`),
      circleImg2: require(`@/assets/images/signup/Ellipse.png`),
    };
  },
  computed: {
    borderColor() {
      return this.is_second ? "#135991" : "#5E5E5E";
    },
    circleImg() {
      return this.is_second
        ? require("@/assets/images/signup/Stepper.png")
        : require("@/assets/images/signup/Ellipse.png");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./SignupProgressComponent.scss";
</style>