<template>
  <loading v-if="is_loading" />
  <div v-else class="signup-bg d-flex justify-content-center">
    <div class="circle-orange"></div>
    <b-row class="d-flex align-items-center px-2 p-lg-5 form-box">
      <b-col md="12" class="px-xl-2 mx-auto">
        <signup-progress-component
          :is_second="is_second"
        ></signup-progress-component>

        <!-- form -->
        <validation-observer ref="signupValidation">
          <b-form
            autocomplete="off"
            class="auth-signup-form mt-2 mx-auto"
            :class="{ 'box-form': is_second }"
            @submit.prevent
          >
            <signup-edu-year-component
              :form_data="form_data"
              :is_second="is_second"
            ></signup-edu-year-component>

            <signup-info-component
              :form_data="form_data"
              :is_second="is_second"
            ></signup-info-component>

            <b-button
              type="submit"
              variant="primary"
              block
              class="btn-signup mx-auto"
              @click="validationForm"
            >
              {{ is_second ? $t("auth.signup") : $t("auth.next") }}
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2 mb-5">
          <b-link :to="{ name: 'Login' }">
            <span class="btn-login text-center">{{ $t("g.backToLogin") }}</span>
          </b-link>
        </b-card-text>
      </b-col>
    </b-row>
    <div class="circle-mix"></div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BLink,
  BCardText,
  BCardTitle,
  BForm,
  BButton,
} from "bootstrap-vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import SignupProgressComponent from "@/components/signup/Progress/SignupProgressComponent.vue";
import SignupEduYearComponent from "@/components/signup/eduYear/SignupEduYearComponent.vue";
import SignupInfoComponent from "@/components/signup/Info/SignupInfoComponent.vue";
import Loading from "@/components/loading/loading.vue";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    ValidationObserver,
    SignupProgressComponent,
    SignupEduYearComponent,
    SignupInfoComponent,
    Loading,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      form_data: {
        first_name: null,
        last_name: null,
        email: null,
        password: null,
        password_confirmation: null,
        phone: null,
        country_id: null,
        country_code: null,
        eduyear_id: null,
        birthdate: null,
      },
      is_loading: true,
      is_second: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  created() {
    this.is_loading = false;
  },
  methods: {
    handleShowLable(form_item) {
      if (form_item === null) {
        this.is_focuse_1 = false;
      }
    },
    validationForm() {
      this.$refs.signupValidation.validate().then((success) => {
        if (success) {
          if (this.is_second) {
            this;
            let formData = new FormData();
            for (const key in this.form_data) {
              if (this.form_data[key] !== null) {
                formData.append(key, this.form_data[key]);
              }
            }
            this.is_loading = true;
            this.$http
              .post(`auth/student/register`, formData)
              .then((res) => {
                if (res.status === 200 || res.status === 201) {
                  this.is_loading = false;
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: res.data.data.message,
                      icon: "EditIcon",
                      variant: "success",
                      text: res.data.data.message,
                    },
                  });
                  this.$router.push({ name: "Login" });
                }
              })
              .catch((err) => {
                this.is_loading = false;
                for (const key in err.response.data.errors) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: this.$t("g.send.errorTitle"),
                      text: err.response.data.errors[key][0],
                      icon: "AlertCircleIcon",
                      variant: "danger",
                    },
                  });
                }
                console.log(err);
              });
          } else {
            this.is_second = true;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-auth.scss";
@import "./index.scss";
</style>
